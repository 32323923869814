@import '~flatpickr/dist/flatpickr.css';
@import '~@core/scss/base/plugins/forms/pickers/form-flat-pickr.scss';

// RTL
html[dir='rtl'] {
  .flatpickr-calendar {
    .flatpickr-prev-month,
    .flatpickr-next-month {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
