











































.toast-main {
  cursor: pointer;
}
.toast-content {
  margin-bottom: 5px;
}
.icon-wrapper {
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 24px;
  width: 24px;
  margin-right: 10px;
  .feather {
    height: 24px;
    width: 24px;
    padding: 5px;
    display: block;
    box-sizing: border-box;
  }
}
