// bs table
.table {
  &.b-table {
    &.b-table-selectable {
      tr {
        outline: none;
      }
    }
    > tbody {
      .b-table-row-selected {
        &.table-active {
          td {
            background-color: $table-hover-bg;
          }
        }
      }
    }

    // Reason: Remove outline on focus
    th {
      outline: none;
    }
  }
}
