








































svg.feather {
  outline: none
}
