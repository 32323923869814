.b-form-datepicker,
.b-form-timepicker {
  label {
    line-height: 200%;
  }

  .btn {
    &.h-auto {
      padding: 0.375rem 0.75rem !important;
    }
    svg {
      margin: 0 !important;
    }
  }
  .form-control {
    min-height: auto !important;
  }
  // text alignment in small
  &.form-control-sm {
    .form-control-sm {
      line-height: 1.65;
    }
  }
}
