/*================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import '@/assets/scss/variables/_variables';

@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

html[dir] body, html[dir] body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow-y: scroll;
  padding-right: 0 !important;

  &.modal-open, &.swal2-shown {
    overflow-y: hidden;
    padding-right: 17px !important;
  }
}

html[dir] body {
  &.dark-layout {
    color: #ccc;
  }
}

hr {
  [dir] body & {
    border-color: $gray-100;
  }

  [dir] body.dark-layout & {
    border-color: $gray-800;
  }
}

/* WeHealth Utilities | start */
.wh-dt {
  font-weight: normal;
  //color: $gray-500;
}

.wh-dd {
  font-weight: bold;
  //color: $primary;
}

.wh-space-bottom {
  padding-bottom: 10rem;
}

.wh-full-height-box {
  min-height: 100vh;
}

.wh-font-family-monospace {
  font-family: $font-family-monospace;
}

.wh-user-select-all {
  user-select: all;
  cursor: cell;
}

.dark-layout {
  .filter-invert, .wh-filter-invert {
    filter: invert(1);
  }
}

.p-05 {
  padding: 0.5rem !important;
}

.pt-05 {
  padding-top: 0.5rem !important;
}

.pr-05 {
  padding-right: 0.5rem !important;
}

.pb-05 {
  padding-bottom: 0.5rem !important;
}

.pl-05 {
  padding-left: 0.5rem !important;
}

.m-05 {
  margin: 0.5rem !important;
}

.mt-05 {
  margin-top: 0.5rem !important;
}

.mr-05 {
  margin-right: 0.5rem
}

.mb-05 {
  margin-bottom: 0.5rem
}

.ml-05 {
  margin-left: 0.5rem !important;
}

.wh-form-range {
  display: flex;
  box-sizing: border-box;

  > div {
    flex-grow: 1;
    flex-shrink: 1;
    border: 1px solid $inputBorderColor;
    box-sizing: border-box;

    .dark-layout & {
      border: 1px solid $inputBorderColorDark;
    }
  }

  > div:nth-child(1) {
    border-radius: 4px 0 0 4px;
  }

  > div:nth-child(2) {
    flex-grow: 0;
    flex-shrink: 0;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    border-left: none;
    border-right: none;
    opacity: 0.6;
  }

  > div:nth-child(3) {
    border-radius: 0 4px 4px 0;
  }

  input {
    border: none;
  }
}

.wh-transition-fade {
  transition: opacity .5s;
}

/* WeHealth Utilities | end */

/* main-menu nav | start */
.navigation {
  font-family: $font-family-sans-serif;
}

[dir] .main-menu.menu-light .navigation > li > a {
  margin: 2px 15px;
}

[dir] .main-menu.menu-light .navigation > li > ul li:first-of-type {
  margin-top: 0 !important;
}

// menu-level-1
.navigation-main > .nav-item > a {
  font-weight: $font-weight-bold;
}

.nav-item.has-sub {
  [dir] body .main-menu.menu-light &.open {
    background: $lightBackgroundColor;
    padding-bottom: 5px;
  }

  [dir] body.dark-layout .main-menu.menu-light &.open {
    background: $darkBackgroundColor;
    padding-bottom: 5px;

    > a {
      background: none !important;
    }
  }

  [dir] body.dark-layout .main-menu-content .navigation-main & .menu-content {
    background: none;
  }
}

[dir] .main-menu ul {
  padding-bottom: 10rem;
}

.main-menu.menu-light .navigation > li.open:not(.menu-item-closing), .main-menu.menu-light .navigation > li.sidebar-group-active {
  [dir] body & {
    background: $lightBackgroundColor;
    box-shadow: inset 0 6px 6px rgb(0 0 0 / 8%), inset 0 -2px 4px rgb(0 0 0 / 4%);

    a {
      background: none;
    }
  }

  [dir] body.dark-layout & {
    background: $darkBackgroundColor;

    a {
      background: none;
    }
  }
}

//.main-menu.menu-light .navigation > li > a, .main-menu.menu-light .navigation > li.active > a {
//  font-weight: bold;
//}

/* main-menu nav | end */

// tabs
[dir] .nav-tabs .nav-link {
  font-weight: bold;
  font-size: 1.2rem;
}

.loading-box {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-box-spinner {
  //position: absolute;
  //left: 50%;
  //top: 50%;
  width: 50px;
  height: 50px;
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: 20rem;
}

.custom-radio {
  display: inline-block;
  margin-right: 20px;
  margin-top: 6px;
}

.default-photo-bg {
  background-color: rgb(200, 200, 200)
}

.group-edit {
  border: 1px solid $inputBorderColor;
  border-radius: 4px;

  .dark-layout & {
    border: 1px solid $inputBorderColorDark;
  }
}

.p-6px {
  padding: 6px;
}

.mt-4px {
  margin-top: 4px;
}

.mt-8px {
  margin-top: 8px;
}

label {
  font-size: 16px;
}

.btn {
  font-size: 16px;
  transition: opacity .5s;

  .input-group-append > &, .input-group-prepend > & {
    font-size: inherit;
  }

  svg {
    transform: scale(1.2);
  }
}

.font-size-16px {
  font-size: 16px;
}

.button-disabled {
  background-image: linear-gradient(47deg, #c2c2c2, #c2c2c2);
}

.box-close {
  position: absolute;
  top: -12px;
  right: 6px;
  color: rgb(255, 187, 187);
}

.row-selected {
  background: #efefef;
}

.display-inline-block {
  display: inline-block;
}

.border-black {
  border: 1px solid black;
}

.text-sub {
  opacity: .7;
  font-size: 86%;
}

.text-green {
  color: rgb(51, 180, 0);
}

.text-red {
  color: rgb(255, 116, 110);
}

.w-60px {
  width: 60px;
}

.user-dropdown-facility {
  display: inline-block;
  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user-dropdown-role {
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.v-select .vs__selected-options {
  height: 32px;
  overflow: hidden;
  word-break: keep-all;
  flex-wrap: nowrap;
}

//[dir] body .v-select.vs--single .vs__selected {
//  margin-top: 4px;
//}

/* 解決 vue-good-table 和 v-select 的樣式衝突問題 | start */
[dir] body.dark-layout .vgt-wrap .vgt-table th .vs__selected-options input, [dir] body.dark-layout .vgt-wrap .vgt-table td .vs__selected-options input, [dir] body.dark-layout .vgt-wrap .vgt-table tr .vs__selected-options input {
  background-color: rgba(0, 0, 0, 0);
  border-color: transparent;
}

/* 解決 vue-good-table 和 v-select 的樣式衝突問題 | end */

/* Make form-elements more visible | start */

[dir] .form-control, [dir] .input-group-text, [dir] body .vs__dropdown-toggle, [dir] .custom-select, [dir] .custom-file-label {
  border-color: $inputBorderColor;

  &::placeholder {
    color: $placeholderColor;
  }
}

[dir] .dark-layout input.form-control, [dir] .dark-layout .custom-file-label, [dir] .dark-layout textarea.form-control {
  background-color: inherit;
}

[dir] .dark-layout input.form-control:not(:focus), [dir] .dark-layout .custom-file-label:not(:focus), [dir] .dark-layout textarea.form-control:not(:focus), [dir] .dark-layout .input-group .input-group-text, [dir] body.dark-layout .vs__dropdown-toggle, [dir] body.dark-layout .vs__dropdown-toggle {
  border-color: $inputBorderColorDark;
  //background: $darkBackgroundColor;

  &::placeholder {
    color: $placeholderColorDark;
  }
}

[dir] .custom-control-label::before {
  border-color: $inputBorderColor;
}

[dir] .dark-layout .custom-control-input:not(:checked) ~ .custom-control-label:before {
  border-color: $inputBorderColorDark;
}

.dark-layout input.form-control[readonly=readonly], .dark-layout .custom-file-label[readonly=readonly], .dark-layout textarea.form-control[readonly=readonly] {
  opacity: 1;
}

[dir] body .vgt-table.bordered td, [dir] body .vgt-table.bordered th {
  border-color: #ccc;
}

html[dir] body.dark-layout .vgt-wrap .vgt-table th, html[dir] body.dark-layout .vgt-wrap .vgt-table td, html[dir] body.dark-layout .vgt-wrap .vgt-table tr {
  border-color: #666;
}

[dir] .vgt-table th.line-numbers, [dir] .vgt-table th.vgt-checkbox-col {
  padding: 0.75em;
  vertical-align: initial;
}

body.dark-layout .vgt-wrap .vgt-table th, body.dark-layout .vgt-wrap .vgt-table td, body.dark-layout .vgt-wrap .vgt-table tr {
  color: $theme-dark-body-color;
}

[dir] .vgt-wrap thead th, [dir] .vgt-table th.vgt-checkbox-col {
  background-color: #f0f0f0;
  color: $gray-500;
}

[dir] .vgt-wrap .vgt-table th.sortable:before, [dir] .vgt-wrap .vgt-table th.sortable:after {
  border-top-color: #b8b8b8;
  border-bottom-color: #b8b8b8;
}

[dir] .vgt-table th.vgt-checkbox-col {
  background-color: #e0e0e0;
}

.vgt-wrap.ws-table-selectable .vgt-table tbody tr:hover {
  th, td {
    background-color: #F1F5FD;
  }

  th.vgt-checkbox-col {
    background-color: #e1e5ed;
  }
}

.dark-layout .vgt-wrap.ws-table-selectable .vgt-table tbody tr:hover {
  th, td {
    background-color: rgba(151, 120, 245, 0.15);
  }
}

.vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__clear, .vs--disabled .vs__search, .vs--disabled .vs__selected, .vs--disabled .vs__open-indicator, input.form-control:disabled, .custom-file-label:disabled, textarea.form-control:disabled {
  html[dir] & {
    background-color: #e8e8e8;
    cursor: not-allowed;
  }

  html[dir] body.dark-layout & {
    opacity: 0.7;
    background-color: #333;
  }
}

html[dir] .vs--single.vs--open .vs__selected {
  position: static;
}

// dropdowns & toast
.vs__dropdown-menu, .Vue-Toastification__toast, .dropdown-menu {
  [dir] body & {
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 30%);
  }

  [dir] body.dark-layout & {
    background-color: $darkBackgroundColor;
  }
}

[dir] body.dark-layout {
  .vs__dropdown-option--highlight {
    background: rgba(151, 120, 245, 0.15);
    color: #ddd !important;
  }

  .dropdown-menu .dropdown-item:hover, .dark-layout .dropdown-menu .dropdown-item:focus {
    background: rgba(151, 120, 245, 0.15);
    color: #ddd;
  }

  .dropdown-divider {
    border-color: #666;
  }
}

/* Make form-elements more visible | end */

/* Simplify Modal pop-ups | start */
.modal-header {
  align-items: center;

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }
}

[dir] body .modal .modal-header .close {
  margin: 0;
  background: none;
  box-shadow: none;
  transform: none;

  &:hover, &:focus, &:active {
    color: #000;
    transform: none;
  }
}

[dir] body.dark-layout .modal .modal-header .close {
  margin: 0;
  background: none !important;
  box-shadow: none !important;
  transform: none;
  color: #ccc;

  &:hover, &:focus, &:active {
    color: #fff;
    transform: none;
  }
}

/* Simplify Modal pop-ups | end */

.btn.disabled, .btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.b-avatar {
  width: 2rem;
  height: 2rem;
}

.b-form-file {
  &, & > input, & > label {
    cursor: pointer !important;
  }
}
